import React, { useContext } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GlobalStateContext } from '../../../context/GlobalContextProvider'
import { withNamespaces } from 'react-i18next'
import '../../../i18n'
import classes from './BlogHeader.module.scss'

const BlogHeader = ({ t }) => {
  const state = useContext(GlobalStateContext)
  const data = useStaticQuery(graphql`
    query {
      german: allDirectusPostsTranslation(filter: { language: { eq: "de" } }) {
        edges {
          node {
            topic
            language
          }
        }
      }
      polish: allDirectusPostsTranslation(filter: { language: { eq: "pl" } }) {
        edges {
          node {
            topic
            language
          }
        }
      }
      english: allDirectusPostsTranslation(filter: { language: { eq: "en" } }) {
        edges {
          node {
            topic
            language
          }
        }
      }
    }
  `)
  let edges = null
  if (state.lang === 'pl-PL') {
    edges = data.polish.edges
  } else if (state.lang === 'de-DE') {
    edges = data.german.edges
  } else {
    edges = data.english.edges
  }
  const array = edges
  const topics = array.map(topic => {
    return { ...topic.node }
  })
  const getUnique = (arr, comp) => {
    const unique = arr
      .map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e])
    return unique
  }
  const uniqueTopics = getUnique(topics, 'topic')
  return (
    <div className={classes.header}>
      <div className={classes.choiceBox}>
        <Link to="/blog" activeStyle={{ color: 'orange' }}>
          <span>{t('allPosts')}</span>
        </Link>
        {uniqueTopics.map(topic => (
          <Link
            to={`${edges[0].node.language}/topic/${topic.topic}`}
            activeStyle={{ color: 'orange' }}
          >
            <span>{topic.topic}</span>
          </Link>
        ))}
      </div>
      <div className={classes.shadow}></div>
      <div className={classes.headerText}>
        <div className={classes.title} style={{ fontSize: 40 }}>
          <h1>{t('blogTitle')}</h1>
        </div>
        <div className={classes.details}>
          <p>{t('blogDetails')}</p>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(BlogHeader)
