import React, { useState } from 'react'
import classes from './Topic.module.scss'
import '../../../i18n'
import { graphql, navigate } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import Layout from '../../../hoc/layout/layout'
import SEO from '../../../hoc/seo'
import BlogHeader from '../../../views/Blog/BlogHeader/BlogHeader'
import Button from '../../UI/Button/Button'

export const query = graphql`
  query Topic($topic: String!, $language: String!) {
    allDirectusPostsTranslation(
      filter: { topic: { eq: $topic }, language: { eq: $language } }
    ) {
      edges {
        node {
          title
          language
          slug
          post_image {
            data {
              full_url
            }
          }
        }
      }
    }
    directusPostsTranslation(topic: { eq: $topic }) {
      topic
    }
  }
`

const Topic = props => {
  const [limit, setLimit] = useState(3)
  const posts = props.data.allDirectusPostsTranslation.edges
  let items = posts.slice(0, limit).map(post => (
    <Grid item xs={12} sm={6} md={4} className={classes.postGridContainer}>
      <div
        role="button"
        className={classes.postBox}
        onClick={() => navigate(`${post.node.language}/blog/${post.node.slug}`)}
        onKeyDown={() =>
          navigate(`${post.node.language}/blog/${post.node.slug}`)
        }
        tabIndex={0}
      >
        <div
          className={classes.postImage}
          style={{
            backgroundImage: `url(${post.node.post_image.data.full_url})`,
          }}
        ></div>
        <div className={classes.postDetails}>
          <div className={classes.title}>
            <h1>{post.node.title}</h1>
          </div>
        </div>
      </div>
    </Grid>
  ))
  const postsLenght = posts.length
  const itemsLength = items.length
  let button = (
    <Button
      onClick={() => setLimit(prev => prev + 3)}
      style={{ marginBottom: 10 }}
    >
      Więcej postów
    </Button>
  )
  if (postsLenght === itemsLength) {
    button = null
  }

  return (
    <Layout>
      <SEO title={props.data.directusPostsTranslation.topic} />
      <BlogHeader
        title={props.data.directusPostsTranslation.topic}
        details="Im Privaten Haftpflichtschutz sind Hunderte von Risiken in deinem
            Alltag mit einer Best-Schutz-Garantie von 50 Millionen Euro
        abgesichert. Eine Auswahl davon findest du in folgender Übersicht…"
        style={{ fontSize: 23 }}
        titleSize="40"
        allPostName="Wszystkie"
      />
      <div className={classes.topicBox}>
        <Grid container className={classes.posts}>
          {items}
        </Grid>
        {button}
      </div>
    </Layout>
  )
}

export default Topic
