import React from 'react'

import classes from './Button.module.scss'

const Button = props => {
  return (
    <div
      role="button"
      onClick={props.onClick}
      onKeyDown={props.onClick}
      className={classes.buttonContainer}
      style={props.style}
      tabIndex={0}
    >
      {props.children}
    </div>
  )
}

export default Button
